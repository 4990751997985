<template>
  <div
    class="mm-quantity"
    :class="{
      'mm-quantity--btns-hidden': isManageBtnsHidden,
      'mm-quantity--disabled': disabled,
      'mm-quantity--error': error,
      'mm-quantity--focus': inputFocus,
    }"
  >
    <div class="mm-quantity__content">
      <div class="mm-quantity-wrapper">
        <div
          v-if="!isManageBtnsHidden"
          class="mm-quantity-icon"
          @click="onQuantityDecrease"
        >
          <SvgIcon
            :src="EIconPath.ActionMinusSvg"
            :wrapper-classes="['mm-numeric-icon', 'minus', isDecreaseDisabled ? 'disabled' : '']"
          />
        </div>

        <input
          :id="inputId"
          ref="inputRef"
          autocomplete="off"
          :value="mask.rawInputValue"
          class="mm-quantity-input mm-font-black-normal"
          :disabled="disabled"
          @focus="onFocusValue"
          @blur="onBlurValue"
          @keyup.enter="onBlurInput"
          @input="onInputValue"
        >

        <div
          v-if="!isManageBtnsHidden"
          class="mm-quantity-icon"
          @click="onQuantityIncrease"
        >
          <SvgIcon
            :src="EIconPath.ActionPlusSvg"
            :wrapper-classes="['mm-numeric-icon', 'plus', isIncreaseDisabled ? 'disabled' : '']"
          />
        </div>
      </div>

      <span
        v-if="unitName"
        class="mm-quantity__unit"
      >
        {{ unitName }}
      </span>
    </div>

    <p
      v-if="error"
      class="mm-max-quantity-alert"
    >
      {{ error }}
    </p>

    <p
      v-if="Number(mask.typedValue) > props.maxValue"
      class="mm-max-quantity-alert"
    >
      доступно {{ props.maxValue }} шт.
    </p>
  </div>
</template>

<script setup lang="ts">
import { EIconPath } from '../enums/iconPath.enum';
import { FieldContext } from 'vee-validate';
import IMask from 'imask';
import { MAX_FRACTION_NUMBER } from '../constants/number.const';
import { ref, computed } from 'vue';
import { WatchSubscription } from '../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../composables/useSSRUnsubscribeWatch';
import { DecimalNumberUtil } from '../utils/decimalNumber.util';

const props = withDefaults(defineProps<{
  // Значение инпута
  modelValue: number;
  // Модификатор, который прибавляется или вычитается
  modifier?: number;
  // Контекст валидации vee validate
  fieldValidation?: FieldContext<unknown>;
  // Максимальное значение
  maxValue?: number;
  // Минимальное значение
  minValue: number;
  // Отключен ли инпут
  disabled?: boolean;
  // Спрятаны ли кнопки управления количеством
  isManageBtnsHidden?: boolean;
  // Обновлять ли значение в реальном времени
  realTimeInput?: boolean;
  // id инпута
  inputId?: string;
  // Количество знаков после запятой
  precision?: number;
  // Сообщение об ошибке
  errorMessage?: string;
  // Наименование единицы измерения
  unitName?: string;
}>(), {
  realTimeInput: false,
  inputId: 'new_product_quantity_input',
  precision: 0,
  modifier: 1,
  maxValue: Number.MAX_SAFE_INTEGER,
});

const emits = defineEmits<{
  (e: 'update:modelValue', value: number, updateValue: () => void): void;
  (e: 'blur', value: number, updateValue: () => void): void;
  (e: 'focus', value: number, updateValue: () => void): void;
}>();

const watchSubscription = new WatchSubscription();
const inputRef = ref<HTMLInputElement>();

const mask = ref<IMask.MaskedNumber>(IMask.createMask({
  mask: Number,
  scale: props.precision,
  radix: ',',
  mapToRadix: ['.', ','],
  max: getMaxValue(),
  thousandsSeparator: ' ',
  normalizeZeros: false,
  padFractionalZeros: true,
  min: props.minValue,
}));

const internalModifier = ref(1);
const inputFocus = ref(false);

const isIncreaseDisabled = computed<boolean>(
  () => mask.value.number >= props.maxValue,
);

const isDecreaseDisabled = computed<boolean>(
  () => mask.value.number <= props.minValue,
);

const error = computed(
  () => props.fieldValidation?.errorMessage.value
    || props.errorMessage,
);

function onChangeValue(): void {
  emits('update:modelValue', mask.value.number, updateValue);
}

function onInputValue(event: Event) {
  const el = event.target as HTMLInputElement;
  if (el.value) {
    mask.value.resolve(el.value);
  }

  if (props.realTimeInput) {
    onChangeValue();
  }
}

function getMinValue(): number {
  return props.minValue ?? 0;
}

function getMaxValue(): number {
  return props.maxValue ?? MAX_FRACTION_NUMBER;
}

function fixValue(value: number): number{
  let internalValue = value;
  const minValue = getMinValue();
  const maxValue = getMaxValue();
  internalValue = internalValue < minValue ? minValue : internalValue;
  internalValue = internalValue > maxValue ? maxValue : internalValue;
  return internalValue;
}

function onBlurInput(): void {
  inputFocus.value = false;
  inputRef.value?.blur();
  setPadFractionalZeros(true);
}

function onBlurValue(): void {
  onBlurInput();
  emits('blur', mask.value.number, updateValue);

  if (!props.realTimeInput) {
    onChangeValue();
  }
}

function onFocusValue(): void {
  inputFocus.value = true;
  setPadFractionalZeros(false);
  emits('focus', mask.value.number, updateValue);
}

function setPadFractionalZeros(padFractionalZeros: boolean): void {
  mask.value.updateOptions({
    padFractionalZeros,
  });
}

function updateValue(): void {
  mask.value.value = props.modelValue?.toString() ?? '0';
}

function onQuantityIncrease(): void {
  if (props.disabled || isIncreaseDisabled.value) {
    return;
  }

  mask.value.number = fixValue(getNearestMultiple(
    DecimalNumberUtil.round(mask.value.number + internalModifier.value, props.precision),
  ));

  onChangeValue();
}

function onQuantityDecrease(): void {
  if (props.disabled || isDecreaseDisabled.value) {
    return;
  }

  mask.value.number = fixValue(getNearestMultiple(
    DecimalNumberUtil.round(mask.value.number - internalModifier.value, props.precision),
  ));

  onChangeValue();
}

function getNearestMultiple(newNumber: number): number {
  return DecimalNumberUtil.round(newNumber / internalModifier.value, props.precision) * internalModifier.value;
}

function setInternalModifier(newModifier = 1, newPrecision = 0): void {
  internalModifier.value = parseFloat(newModifier.toFixed(newPrecision));
}

function fixMaskNumberValue(value = mask.value.number): void {
  mask.value.number = fixValue(value);
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    () => updateValue(),
    { immediate: true },
  ),

  watch(
    () => props.maxValue,
    (newMaxValue) => {
      mask.value.updateOptions({ max: newMaxValue });
      fixMaskNumberValue();
      onChangeValue();
    },
  ),

  watch(
    () => props.minValue,
    (newMinValue) => {
      mask.value.updateOptions({ min: newMinValue });
      fixMaskNumberValue();
      onChangeValue();
    },
  ),
  watch(
    () => [props.modifier, props.precision],
    ([newModifier, newPrecision]) => setInternalModifier(newModifier, newPrecision),
    { immediate: true },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

defineExpose({
  updateValue,
});

onBeforeMount(() => {
  fixMaskNumberValue(props.modelValue);
});
</script>

<style lang="scss" scoped>
@import 'styles/base/common/_variables';

.mm-quantity {
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .mm-quantity-wrapper {
    transition: $transition-speed ease;
    width: 100%;
    height: 100%;
    border: 1px solid $gray-200;
    border-radius: 6px;
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mm-quantity-icon {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 0;

      &:first-child {
        margin-right: 3px;
      }

      &:last-child {
        justify-content: flex-end;
        margin-left: 3px;
      }
    }

    input {
      border: none;
      background-color: transparent;
      all: unset;
      width: 100%;
      height: 100%;
      flex: 1;
      text-align: center;
      -moz-appearance: textfield;
    }

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      appearance: none;
    }
  }

  &--btns-hidden {
    .mm-quantity-wrapper {
      input {
        width: 100%;
      }
    }
  }

  &--disabled {
    .mm-quantity-wrapper {
      background-color: $input-disabled-border;
    }

    .mm-quantity__unit {
      color: $text-disabled;
    }
  }

  &--error {
    .mm-quantity-wrapper {
      border-color: $red;
    }
  }

  .mm-max-quantity-alert {
    color: $indicator;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 0;
  }

  &:hover,
  &--focus {
    & > .mm-quantity-wrapper {
      border: 1px solid $text-disabled;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__unit {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text-black;
    margin-left: 7px;
  }
}
</style>
